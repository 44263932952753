export const sampledata = [
  {
    id: '3727ffae-fe62-4c3f-9b7a-dae6fd35cdaf',
    name: '2CELLOS',
    bio: null,
    img: 'https://yt3.ggpht.com/a/AATXAJxbVFg7UYvX06_Nx85ak5sFwJmHp0-KwxfKlNSAKw=s800-c-k-c0xffffffff-no-rj-mo',
    twitter: 89794,
    youtube: 5990000,
    spotify: 873391,
    archetypes: [],
    demographics: {
      source: 'survey',
      gender: {
        male: '47.1739912315',
        female: '52.8260087685',
      },
      ethnicity: {
        hispanic: '7.86131172466',
        black: '2.96729364229',
        white: '74.6628575254',
        asian: '0.341494177408',
        'asian or pacific islander': '14.1670429302',
      },
      income: {
        k_25_50: '13.1914893617',
        k_50_100: '36.170212766',
        k_100_150: '24.6808510638',
        k_150_plus: '9.78723404255',
        k_under_25: '16.170212766',
      },
      education: {
        bachelor_degree: '37.0434782609',
        graduate_degree: '25.3913043478',
        high_school_degree: '6.60869565217',
        less_than_high_school_degree: '2.60869565217',
        some_college_or_associate_degree: '28.347826087',
      },
      age: {
        a18_29: '29.9130434783',
        a30_44: '33.7391304348',
        a45_60: '25.0434782609',
        a60_plus: '11.3043478261',
      },
      location: {
        pacific: {
          popularity: '18.8299817185',
          lat: '36.114647',
          long: '-115.172813',
        },
        mountain: {
          popularity: '9.32358318099',
          lat: '42.3221498',
          long: '-111.2977044',
        },
        new_england: {
          popularity: '7.67824497258',
          lat: '43.008663',
          long: '-71.454391',
        },
        south_atlantic: {
          popularity: '11.1517367459',
          lat: '35.227085',
          long: '-80.843124',
        },
        middle_atlantic: {
          popularity: '17.7330895795',
          lat: '39.962303',
          long: '-76.727692',
        },
        east_north_central: {
          popularity: '12.4314442413',
          lat: '41.881832',
          long: '-87.623177',
        },
        east_south_central: {
          popularity: '9.1407678245',
          lat: '36.174465',
          long: '-86.767960',
        },
        west_south_central: {
          popularity: '13.7111517367',
          lat: '32.779167',
          long: '-96.808891',
        },
      },
      activities: {
        crafts: '27.4955570745',
        cooking: '54.1086807929',
        fashion: '13.3663704716',
        traveling: '48.6315789474',
        team_sports: '21.2904989747',
        reading_books: '72.3718386876',
        watching_sports: '34.2241968558',
        individual_sports: '32.7559808612',
        outdoor_activities: '56.4846206425',
        playing_an_instrument: '20.4839371155',
      },
      personality: {
        artsy: '10.0862641009',
        preppy: '13.8686131387',
        sporty: '6.30391506304',
        alternative: '12.0769741208',
        urban_hip_hop: '0.0',
        trendy_hipster: '6.30391506304',
        average_joe_jane: '51.3603185136',
      },
      personality_traits: {
        fun: '19.7928913192',
        warm: '4.49419002051',
        happy: '2.87081339713',
        tense: '1.4025974026',
        tough: '9.05263157895',
        dreamy: '10.4176349966',
        unique: '12.2617908407',
        upbeat: '17.6363636364',
        amusing: '3.93711551606',
        intense: '7.75393028025',
        sensual: '4.55844155844',
        stylish: '2.09159261791',
        charming: '6.88448393712',
        peaceful: '7.96103896104',
        reliable: '4.21052631579',
        romantic: '8.57211209843',
        energetic: '36.9200273411',
        inspiring: '15.8215994532',
        wholesome: '10.5194805195',
        aggressive: '1.05263157895',
        depressing: '1.14285714286',
        passionate: '20.6773752563',
        rebellious: '14.7163362953',
        adventurous: '5.16951469583',
        imaginative: '10.9357484621',
        intelligent: '5.36363636364',
        conventional: '7.88448393712',
        down_to_earth: '5.71633629528',
        sophisticated: '5.98701298701',
      },
    },
    reach: {
      '24h': null,
      '7days': '1505929322',
      '30days': '1505929322',
      total: '479819094231',
      daily: [
        {
          view_count: 1505929322,
          created_at: '2022-02-13',
        },
      ],
    },
    total_followers: {
      spotify: [
        {
          date: '2022-01-17',
          value: 865201,
        },
        {
          date: '2022-01-17',
          value: 865201,
        },
        {
          date: '2022-01-17',
          value: 865201,
        },
        {
          date: '2022-01-18',
          value: 865517,
        },
        {
          date: '2022-01-18',
          value: 865517,
        },
        {
          date: '2022-01-18',
          value: 865517,
        },
        {
          date: '2022-01-19',
          value: 865751,
        },
        {
          date: '2022-01-19',
          value: 865751,
        },
        {
          date: '2022-01-19',
          value: 865751,
        },
        {
          date: '2022-01-20',
          value: 865999,
        },
        {
          date: '2022-01-20',
          value: 865999,
        },
        {
          date: '2022-01-20',
          value: 865999,
        },
        {
          date: '2022-01-21',
          value: 866287,
        },
        {
          date: '2022-01-21',
          value: 866287,
        },
        {
          date: '2022-01-21',
          value: 866287,
        },
        {
          date: '2022-01-22',
          value: 866572,
        },
        {
          date: '2022-01-22',
          value: 866572,
        },
        {
          date: '2022-01-22',
          value: 866572,
        },
        {
          date: '2022-01-23',
          value: 866886,
        },
        {
          date: '2022-01-23',
          value: 866886,
        },
        {
          date: '2022-01-23',
          value: 866886,
        },
        {
          date: '2022-01-24',
          value: 867199,
        },
        {
          date: '2022-01-24',
          value: 867199,
        },
        {
          date: '2022-01-24',
          value: 867199,
        },
        {
          date: '2022-01-25',
          value: 867483,
        },
        {
          date: '2022-01-25',
          value: 867483,
        },
        {
          date: '2022-01-25',
          value: 867483,
        },
        {
          date: '2022-01-26',
          value: 867739,
        },
        {
          date: '2022-01-26',
          value: 867739,
        },
        {
          date: '2022-01-26',
          value: 867739,
        },
        {
          date: '2022-01-27',
          value: 868019,
        },
        {
          date: '2022-01-27',
          value: 868019,
        },
        {
          date: '2022-01-27',
          value: 868019,
        },
        {
          date: '2022-01-28',
          value: 868287,
        },
        {
          date: '2022-01-28',
          value: 868287,
        },
        {
          date: '2022-01-28',
          value: 868287,
        },
        {
          date: '2022-01-29',
          value: 868578,
        },
        {
          date: '2022-01-29',
          value: 868578,
        },
        {
          date: '2022-01-29',
          value: 868578,
        },
        {
          date: '2022-01-30',
          value: 868881,
        },
        {
          date: '2022-01-30',
          value: 868881,
        },
        {
          date: '2022-01-30',
          value: 868881,
        },
        {
          date: '2022-01-31',
          value: 869186,
        },
        {
          date: '2022-01-31',
          value: 869186,
        },
        {
          date: '2022-01-31',
          value: 869186,
        },
        {
          date: '2022-02-01',
          value: 869427,
        },
        {
          date: '2022-02-01',
          value: 869427,
        },
        {
          date: '2022-02-01',
          value: 869427,
        },
        {
          date: '2022-02-02',
          value: 869713,
        },
        {
          date: '2022-02-02',
          value: 869713,
        },
        {
          date: '2022-02-02',
          value: 869713,
        },
        {
          date: '2022-02-03',
          value: 869988,
        },
        {
          date: '2022-02-03',
          value: 869988,
        },
        {
          date: '2022-02-03',
          value: 869988,
        },
        {
          date: '2022-02-04',
          value: 870295,
        },
        {
          date: '2022-02-04',
          value: 870295,
        },
        {
          date: '2022-02-04',
          value: 870295,
        },
        {
          date: '2022-02-05',
          value: 870625,
        },
        {
          date: '2022-02-05',
          value: 870625,
        },
        {
          date: '2022-02-05',
          value: 870625,
        },
        {
          date: '2022-02-06',
          value: 870927,
        },
        {
          date: '2022-02-06',
          value: 870927,
        },
        {
          date: '2022-02-06',
          value: 870927,
        },
        {
          date: '2022-02-07',
          value: 871214,
        },
        {
          date: '2022-02-07',
          value: 871214,
        },
        {
          date: '2022-02-07',
          value: 871214,
        },
        {
          date: '2022-02-08',
          value: 871502,
        },
        {
          date: '2022-02-08',
          value: 871502,
        },
        {
          date: '2022-02-08',
          value: 871502,
        },
        {
          date: '2022-02-09',
          value: 871749,
        },
        {
          date: '2022-02-09',
          value: 871749,
        },
        {
          date: '2022-02-09',
          value: 871749,
        },
        {
          date: '2022-02-10',
          value: 872011,
        },
        {
          date: '2022-02-10',
          value: 872011,
        },
        {
          date: '2022-02-10',
          value: 872011,
        },
        {
          date: '2022-02-11',
          value: 872269,
        },
        {
          date: '2022-02-11',
          value: 872269,
        },
        {
          date: '2022-02-11',
          value: 872269,
        },
        {
          date: '2022-02-12',
          value: 872588,
        },
        {
          date: '2022-02-12',
          value: 872588,
        },
        {
          date: '2022-02-12',
          value: 872588,
        },
        {
          date: '2022-02-13',
          value: 872813,
        },
        {
          date: '2022-02-13',
          value: 872813,
        },
        {
          date: '2022-02-13',
          value: 872813,
        },
        {
          date: '2022-02-14',
          value: 873124,
        },
        {
          date: '2022-02-14',
          value: 873124,
        },
        {
          date: '2022-02-14',
          value: 873124,
        },
        {
          date: '2022-02-15',
          value: 873391,
        },
        {
          date: '2022-02-15',
          value: 873391,
        },
        {
          date: '2022-02-15',
          value: 873391,
        },
      ],
      youtube: [
        {
          date: '2022-02-14',
          value: 5990000,
        },
      ],
      twitter: [
        {
          date: '2022-01-16',
          value: 89742,
        },
        {
          date: '2022-01-17',
          value: 89742,
        },
        {
          date: '2022-01-18',
          value: 89740,
        },
        {
          date: '2022-01-19',
          value: 89747,
        },
        {
          date: '2022-01-20',
          value: 89747,
        },
        {
          date: '2022-01-21',
          value: 89747,
        },
        {
          date: '2022-01-22',
          value: 89737,
        },
        {
          date: '2022-01-23',
          value: 89738,
        },
        {
          date: '2022-01-24',
          value: 89738,
        },
        {
          date: '2022-01-25',
          value: 89732,
        },
        {
          date: '2022-01-26',
          value: 89736,
        },
        {
          date: '2022-01-27',
          value: 89734,
        },
        {
          date: '2022-01-28',
          value: 89734,
        },
        {
          date: '2022-01-29',
          value: 89745,
        },
        {
          date: '2022-01-30',
          value: 89757,
        },
        {
          date: '2022-01-31',
          value: 89760,
        },
        {
          date: '2022-02-01',
          value: 89757,
        },
        {
          date: '2022-02-02',
          value: 89760,
        },
        {
          date: '2022-02-03',
          value: 89766,
        },
        {
          date: '2022-02-04',
          value: 89766,
        },
        {
          date: '2022-02-05',
          value: 89775,
        },
        {
          date: '2022-02-06',
          value: 89779,
        },
        {
          date: '2022-02-07',
          value: 89763,
        },
        {
          date: '2022-02-08',
          value: 89763,
        },
        {
          date: '2022-02-09',
          value: 89766,
        },
        {
          date: '2022-02-10',
          value: 89771,
        },
        {
          date: '2022-02-11',
          value: 89779,
        },
        {
          date: '2022-02-12',
          value: 89789,
        },
        {
          date: '2022-02-13',
          value: 89792,
        },
        {
          date: '2022-02-14',
          value: 89793,
        },
        {
          date: '2022-02-15',
          value: 89794,
        },
      ],
    },
    follower_growth: {
      spotify: {
        grows24h: 267,
        percentage24h: '0.03',
        grows7days: 1889,
        percentage7days: '0.22',
        grows30days: 8478,
        percentage30days: '0.97',
      },
      twitter: {
        grows24h: 1,
        percentage24h: '0.00',
        grows7days: 30,
        percentage7days: '0.03',
        grows30days: 63,
        percentage30days: '0.07',
      },
      youtube: {
        grows24h: 0,
        percentage24h: 0,
        grows7days: 0,
        percentage7days: 0,
        grows30days: 0,
        percentage30days: 0,
      },
    },
  },
  {
    id: 'af8a5fb5-d15f-43ff-9bbb-8124f17bd048',
    name: '2 Chainz',
    bio: "I am the Drench God aka Tity Boi aka Soufside's own Hairweavekiller, but yall know me as 2 Chainz!! \n",
    img: 'https://yt3.ggpht.com/a/AATXAJze3fu79M99kplpJBuMFKlMOBI0OpQCso9sWBv_hg=s800-c-k-c0xffffffff-no-rj-mo',
    twitter: 4455302,
    youtube: 3820000,
    spotify: 6926716,
    archetypes: [],
    demographics: {
      source: 'survey',
      gender: {
        male: '70.7863488303',
        female: '29.2136511697',
      },
      ethnicity: {
        hispanic: '4.332871934',
        black: '10.4086337043',
        white: '73.5196279276',
        asian: '4.10811509145',
        'asian or pacific islander': '7.63075134267',
      },
      income: {
        k_25_50: '55.5555555556',
        k_50_100: '11.1111111111',
        k_100_150: '0.0',
        k_150_plus: '11.1111111111',
        k_under_25: '22.2222222222',
      },
      education: {
        bachelor_degree: '33.3333333333',
        graduate_degree: '0.0',
        high_school_degree: '33.3333333333',
        less_than_high_school_degree: '0.0',
        some_college_or_associate_degree: '33.3333333333',
      },
      age: {
        a18_29: '60.0',
        a30_44: '40.0',
        a45_60: '0.0',
        a60_plus: '0.0',
      },
      location: {
        pacific: {
          popularity: '33.3333333333',
          lat: '36.114647',
          long: '-115.172813',
        },
        mountain: {
          popularity: '11.1111111111',
          lat: '42.3221498',
          long: '-111.2977044',
        },
        new_england: {
          popularity: '0.0',
          lat: '43.008663',
          long: '-71.454391',
        },
        south_atlantic: {
          popularity: '11.1111111111',
          lat: '35.227085',
          long: '-80.843124',
        },
        middle_atlantic: {
          popularity: '0.0',
          lat: '39.962303',
          long: '-76.727692',
        },
        east_north_central: {
          popularity: '11.1111111111',
          lat: '41.881832',
          long: '-87.623177',
        },
        east_south_central: {
          popularity: '0.0',
          lat: '36.174465',
          long: '-86.767960',
        },
        west_south_central: {
          popularity: '33.3333333333',
          lat: '32.779167',
          long: '-96.808891',
        },
      },
      activities: {
        crafts: '40.0',
        cooking: '40.0',
        fashion: '30.0',
        traveling: '50.0',
        team_sports: '50.0',
        reading_books: '20.0',
        watching_sports: '40.0',
        individual_sports: '20.0',
        outdoor_activities: '50.0',
        playing_an_instrument: '20.0',
      },
      personality: {
        artsy: '10.0',
        preppy: '0.0',
        sporty: '20.0',
        alternative: '0.0',
        urban_hip_hop: '20.0',
        trendy_hipster: '20.0',
        average_joe_jane: '30.0',
      },
      personality_traits: {
        fun: '20.0',
        warm: '10.0',
        happy: '10.0',
        tense: '0.0',
        tough: '0.0',
        dreamy: '10.0',
        unique: '0.0',
        upbeat: '30.0',
        amusing: '30.0',
        intense: '10.0',
        sensual: '0.0',
        stylish: '0.0',
        charming: '10.0',
        peaceful: '0.0',
        reliable: '0.0',
        romantic: '0.0',
        energetic: '20.0',
        inspiring: '10.0',
        wholesome: '10.0',
        aggressive: '0.0',
        depressing: '10.0',
        passionate: '20.0',
        rebellious: '10.0',
        adventurous: '10.0',
        imaginative: '10.0',
        intelligent: '0.0',
        conventional: '10.0',
        down_to_earth: '10.0',
        sophisticated: '0.0',
      },
    },
    reach: {
      '24h': null,
      '7days': '3845072469',
      '30days': '9585090855',
      total: '361928322055',
      daily: [
        {
          view_count: 1907635187,
          created_at: '2022-01-25',
        },
        {
          view_count: 1918471483,
          created_at: '2022-02-07',
        },
        {
          view_count: 1913911716,
          created_at: '2022-02-03',
        },
        {
          view_count: 1920526818,
          created_at: '2022-02-09',
        },
        {
          view_count: 1924545651,
          created_at: '2022-02-13',
        },
      ],
    },
    total_followers: {
      spotify: [
        {
          date: '2022-01-17',
          value: 6851620,
        },
        {
          date: '2022-01-17',
          value: 6851620,
        },
        {
          date: '2022-01-17',
          value: 6851620,
        },
        {
          date: '2022-01-18',
          value: 6853883,
        },
        {
          date: '2022-01-18',
          value: 6853883,
        },
        {
          date: '2022-01-18',
          value: 6853883,
        },
        {
          date: '2022-01-19',
          value: 6856077,
        },
        {
          date: '2022-01-19',
          value: 6856077,
        },
        {
          date: '2022-01-19',
          value: 6856077,
        },
        {
          date: '2022-01-20',
          value: 6858348,
        },
        {
          date: '2022-01-20',
          value: 6858348,
        },
        {
          date: '2022-01-20',
          value: 6858348,
        },
        {
          date: '2022-01-21',
          value: 6860745,
        },
        {
          date: '2022-01-21',
          value: 6860745,
        },
        {
          date: '2022-01-21',
          value: 6860745,
        },
        {
          date: '2022-01-22',
          value: 6863252,
        },
        {
          date: '2022-01-22',
          value: 6863252,
        },
        {
          date: '2022-01-22',
          value: 6863252,
        },
        {
          date: '2022-01-23',
          value: 6865844,
        },
        {
          date: '2022-01-23',
          value: 6865844,
        },
        {
          date: '2022-01-23',
          value: 6865844,
        },
        {
          date: '2022-01-24',
          value: 6868338,
        },
        {
          date: '2022-01-24',
          value: 6868338,
        },
        {
          date: '2022-01-24',
          value: 6868338,
        },
        {
          date: '2022-01-25',
          value: 6870590,
        },
        {
          date: '2022-01-25',
          value: 6870590,
        },
        {
          date: '2022-01-25',
          value: 6870590,
        },
        {
          date: '2022-01-26',
          value: 6872926,
        },
        {
          date: '2022-01-26',
          value: 6872926,
        },
        {
          date: '2022-01-26',
          value: 6872926,
        },
        {
          date: '2022-01-27',
          value: 6875371,
        },
        {
          date: '2022-01-27',
          value: 6875371,
        },
        {
          date: '2022-01-27',
          value: 6875371,
        },
        {
          date: '2022-01-28',
          value: 6877940,
        },
        {
          date: '2022-01-28',
          value: 6877940,
        },
        {
          date: '2022-01-28',
          value: 6877940,
        },
        {
          date: '2022-01-29',
          value: 6880463,
        },
        {
          date: '2022-01-29',
          value: 6880463,
        },
        {
          date: '2022-01-29',
          value: 6880463,
        },
        {
          date: '2022-01-30',
          value: 6883022,
        },
        {
          date: '2022-01-30',
          value: 6883022,
        },
        {
          date: '2022-01-30',
          value: 6883022,
        },
        {
          date: '2022-01-31',
          value: 6885495,
        },
        {
          date: '2022-01-31',
          value: 6885495,
        },
        {
          date: '2022-01-31',
          value: 6885495,
        },
        {
          date: '2022-02-01',
          value: 6887637,
        },
        {
          date: '2022-02-01',
          value: 6887637,
        },
        {
          date: '2022-02-01',
          value: 6887637,
        },
        {
          date: '2022-02-02',
          value: 6889828,
        },
        {
          date: '2022-02-02',
          value: 6889828,
        },
        {
          date: '2022-02-02',
          value: 6889828,
        },
        {
          date: '2022-02-03',
          value: 6892467,
        },
        {
          date: '2022-02-03',
          value: 6892467,
        },
        {
          date: '2022-02-03',
          value: 6892467,
        },
        {
          date: '2022-02-04',
          value: 6895126,
        },
        {
          date: '2022-02-04',
          value: 6895126,
        },
        {
          date: '2022-02-04',
          value: 6895126,
        },
        {
          date: '2022-02-05',
          value: 6897775,
        },
        {
          date: '2022-02-05',
          value: 6897775,
        },
        {
          date: '2022-02-05',
          value: 6897775,
        },
        {
          date: '2022-02-06',
          value: 6902019,
        },
        {
          date: '2022-02-06',
          value: 6902019,
        },
        {
          date: '2022-02-06',
          value: 6902019,
        },
        {
          date: '2022-02-07',
          value: 6905036,
        },
        {
          date: '2022-02-07',
          value: 6905036,
        },
        {
          date: '2022-02-07',
          value: 6905036,
        },
        {
          date: '2022-02-08',
          value: 6907935,
        },
        {
          date: '2022-02-08',
          value: 6907935,
        },
        {
          date: '2022-02-08',
          value: 6907935,
        },
        {
          date: '2022-02-09',
          value: 6910608,
        },
        {
          date: '2022-02-09',
          value: 6910608,
        },
        {
          date: '2022-02-09',
          value: 6910608,
        },
        {
          date: '2022-02-10',
          value: 6913585,
        },
        {
          date: '2022-02-10',
          value: 6913585,
        },
        {
          date: '2022-02-10',
          value: 6913585,
        },
        {
          date: '2022-02-11',
          value: 6916408,
        },
        {
          date: '2022-02-11',
          value: 6916408,
        },
        {
          date: '2022-02-11',
          value: 6916408,
        },
        {
          date: '2022-02-12',
          value: 6919867,
        },
        {
          date: '2022-02-12',
          value: 6919867,
        },
        {
          date: '2022-02-12',
          value: 6919867,
        },
        {
          date: '2022-02-13',
          value: 6921986,
        },
        {
          date: '2022-02-13',
          value: 6921986,
        },
        {
          date: '2022-02-13',
          value: 6921986,
        },
        {
          date: '2022-02-14',
          value: 6924626,
        },
        {
          date: '2022-02-14',
          value: 6924626,
        },
        {
          date: '2022-02-14',
          value: 6924626,
        },
        {
          date: '2022-02-15',
          value: 6926716,
        },
        {
          date: '2022-02-15',
          value: 6926716,
        },
        {
          date: '2022-02-15',
          value: 6926716,
        },
      ],
      youtube: [
        {
          date: '2022-01-26',
          value: 3800000,
        },
        {
          date: '2022-02-08',
          value: 3810000,
        },
        {
          date: '2022-02-04',
          value: 3810000,
        },
        {
          date: '2022-02-10',
          value: 3820000,
        },
        {
          date: '2022-02-14',
          value: 3820000,
        },
      ],
      twitter: [
        {
          date: '2022-01-16',
          value: 4451987,
        },
        {
          date: '2022-01-17',
          value: 4452099,
        },
        {
          date: '2022-01-18',
          value: 4452033,
        },
        {
          date: '2022-01-19',
          value: 4452123,
        },
        {
          date: '2022-01-20',
          value: 4452178,
        },
        {
          date: '2022-01-21',
          value: 4452272,
        },
        {
          date: '2022-01-22',
          value: 4452405,
        },
        {
          date: '2022-01-23',
          value: 4452530,
        },
        {
          date: '2022-01-24',
          value: 4452629,
        },
        {
          date: '2022-01-25',
          value: 4452439,
        },
        {
          date: '2022-01-26',
          value: 4452481,
        },
        {
          date: '2022-01-27',
          value: 4452621,
        },
        {
          date: '2022-01-28',
          value: 4452709,
        },
        {
          date: '2022-01-29',
          value: 4452890,
        },
        {
          date: '2022-01-30',
          value: 4452824,
        },
        {
          date: '2022-01-31',
          value: 4453188,
        },
        {
          date: '2022-02-01',
          value: 4453211,
        },
        {
          date: '2022-02-02',
          value: 4453115,
        },
        {
          date: '2022-02-03',
          value: 4453182,
        },
        {
          date: '2022-02-04',
          value: 4453439,
        },
        {
          date: '2022-02-05',
          value: 4453714,
        },
        {
          date: '2022-02-06',
          value: 4453812,
        },
        {
          date: '2022-02-07',
          value: 4453774,
        },
        {
          date: '2022-02-08',
          value: 4453700,
        },
        {
          date: '2022-02-09',
          value: 4454056,
        },
        {
          date: '2022-02-10',
          value: 4454430,
        },
        {
          date: '2022-02-11',
          value: 4454616,
        },
        {
          date: '2022-02-12',
          value: 4454823,
        },
        {
          date: '2022-02-13',
          value: 4454964,
        },
        {
          date: '2022-02-14',
          value: 4455205,
        },
        {
          date: '2022-02-15',
          value: 4455302,
        },
      ],
    },
    follower_growth: {
      spotify: {
        grows24h: 2090,
        percentage24h: '0.03',
        grows7days: 18781,
        percentage7days: '0.27',
        grows30days: 77826,
        percentage30days: '1.12',
      },
      twitter: {
        grows24h: 241,
        percentage24h: '0.01',
        grows7days: 1431,
        percentage7days: '0.03',
        grows30days: 3328,
        percentage30days: '0.07',
      },
      youtube: {
        grows24h: 0,
        percentage24h: 0,
        grows7days: 0,
        percentage7days: 0,
        grows30days: 0,
        percentage30days: 0,
      },
    },
  },
];
